(() => {
  const toggles = document.querySelectorAll(".features-slide-toggle");
  const togglesContainer = document.querySelector(".features-slide-toggles");

  function scrollToToggle(toggle) {
    toggle.addEventListener("click", function () {
      // console.log("toggle.offsetLeft", toggle.offsetLeft);
      // console.log("togglesContainer.offsetWidth", togglesContainer.offsetWidth);
      // console.log("toggle.offsetWidth", toggle.offsetWidth);

      togglesContainer.scrollLeft =
        toggle.offsetLeft -
        (togglesContainer.offsetWidth - toggle.offsetWidth) / 2;
    });
  }
  for (const toggle of toggles) scrollToToggle(toggle);
})();
